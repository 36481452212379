<script>
import { mapGetters } from "vuex";
import creditsPurchase from "@/components/credits-purchase.vue";
export default {
  name: "account-credits",

  metaInfo: {
    title: "Mis Créditos",
  },

  data() {
    return {
      quantity: 1,
    };
  },

  computed: {
    ...mapGetters("config", ["credits_bundles_options"]),
    ...mapGetters("session", ["currentAccount"]),
  },

  methods: {
    creditsPurchase($quantity) {
      this.$sitio.modal.open({
        component: creditsPurchase,
        parent: this,
        props: { preselect: $quantity },
        fullScreen: true,
      });
    },
  },
};
</script>

<template>
  <div id="account_plan">
    <div class="page__header ui_padding container">
      <h1 class="page__title">Mis Créditos</h1>
    </div>
    <div class="page__section ui_padding container">
      <div class="content">
        <p>Los créditos son la moneda virtual de Sitio.</p>
        <p>
          Estos permiten a los anunciantes realizar operaciones sin la necesidad
          de incurrir en pagos recurrentes.
        </p>
        <p>
          <strong>Balance actual:</strong>&nbsp;
          <strong
            >${{ currentAccount.user_credits | formatMoney(0) }}
            {{
              currentAccount.user_credits === 1 ? "crédito" : "créditos"
            }}</strong
          >
        </p>
        <button
          @click="creditsPurchase()"
          class="button is-link"
          v-text="'Comprar créditos'"
        />
      </div>
      <!-- <div
        v-for="(bundle, index) in credits_bundles_options"
        :key="index"
        class="purchase__option"
        @click.prevent="quantity = bundle.quantity"
      >
        <b-radio v-model="quantity" :native-value="bundle.quantity" />
        <span>
          <span>{{ bundle.quantity | formatMoney(0) }} </span>
          <span v-if="bundle.quantity > 1">Créditos </span>
          <span v-else>Crédito </span>
        </span>
        <span>por USD ${{ bundle.total | formatMoney(0) }}</span>
      </div> -->
      <!-- <b-button @click="creditsPurchase()" type="is-link">Recargar</b-button> -->

      <hr />
      <h3 class="section__title">Movimientos</h3>
      <div class="ibox">
        <span><b>Todos</b> | <b>Débitos</b> | <b>Créditos</b></span>
      </div>

      <!-- <hr />
      <h3 class="section__title">Regalos salientes</h3>
      <div class="ibox">
        <span>No tienes ningún regalo pendiente</span>
      </div> -->

      <!-- <hr />
      <h3 class="section__title">Regalos entrantes</h3>
      <div class="ibox">
        <span>No tienes ningún regalo pendiente</span>
      </div> -->

      <hr />
      <h3 class="section__title">Ordenes</h3>
      <div class="ibox">
        Próximamente
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.purchase__option {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
