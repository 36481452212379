<script>
import { wpService } from "@/services/wp";
import { mapGetters } from "vuex";
import { paypalSetup } from "@/utils/helpers.js";
export default {
  props: {
    preselect: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      selection: this.preselect,
      step: 0,
      paypalSDK: null,
    };
  },

  computed: {
    ...mapGetters("config", [
      "credits_bundles_options",
      "credits_bundles_find",
    ]),
    item() {
      return this.credits_bundles_find(this.selection);
    },
  },

  created() {
    if (this.item) this.step++;
  },

  mounted() {
    paypalSetup().then(paypalSDK => {
      console.log(paypalSDK);
      if (paypalSDK) {
        this.paypalSDK = paypalSDK;
      } else {
        this.$buefy.dialog.alert({
          message:
            "La pasarela de pagos no esta disponible en estos momentos. Por favor, intente mas tarde.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        this.$parent.close();
      }
    });
  },

  watch: {
    $route() {
      this.$parent.close();
    },
    step(value) {
      if (value == 1) {
        // console.log("paypal is ready");
        let $this = this;
        this.$nextTick(() => {
          this.paypalSDK
            .Buttons({
              createOrder: function() {
                return $this.createOrder();
              },
              onApprove: function(data) {
                // console.log("onApprove data", data);
                $this.captureOrder(data.orderID);
              },
            })
            .render("#paypal-button-container");
        });
      }
    },
  },

  methods: {
    setSelection($quantity) {
      this.selection = $quantity;
      this.step++;
    },
    createOrder() {
      return wpService.credits.create(this.item.quantity).then(response => {
        return response.id;
      });
    },
    captureOrder(paypalOrderID) {
      let loading = this.$buefy.loading.open({ container: null });
      return wpService.credits
        .capture(paypalOrderID)
        .then(response => {
          console.log(response);
          this.$parent.close();
          loading.close();
          this.$buefy.dialog.alert("Su orden fue completada con exito!");
        })
        .catch(err => {
          console.log(err);
          this.$parent.close();
          loading.close();
          this.$buefy.dialog.alert({
            message:
              "There was a problem processing your order. Try again later.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
          });
        });
    },
  },
};
</script>

<template>
  <div id="checkout">
    <b-loading :active="!paypalSDK"></b-loading>
    <section class="config" v-if="paypalSDK && step == 0">
      <h2 class="title">Compra</h2>
      <!-- <div class="content">
        <b-field label="Selección de plan">
          <b-select v-model="selection">
            <option
              v-for="option in credits_bundles_options"
              :key="option.quantity"
              :value="option.quantity"
              >{{ option.quantity | formatMoney(0) }}
              {{ option.quantity > 1 ? "Créditos" : "Crédito" }} por ${{
                option.total | formatMoney(0)
              }}
              USD</option
            >
          </b-select>
        </b-field>
      </div>
      <b-button @click="step++">Continuar</b-button> -->
      <div class="content">
        <div class="label">Paquetes disponibles:</div>
        <div class="options">
          <div
            v-for="option in credits_bundles_options"
            :key="option.quantity"
            @click="setSelection(option.quantity)"
          >
            <div class="quantity">
              {{ option.quantity | formatMoney(0) }}
              {{ option.quantity > 1 ? "Créditos" : "Crédito" }}
            </div>
            <div class="value">${{ option.total | formatMoney(0) }} USD</div>
            <div class="choose">
              <b-button size="is-small" type="is-success" rounded
                >Comprar</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="checkout" v-if="paypalSDK && step == 1">
      <h2 class="title">Checkout</h2>
      <div class="content">
        <div class="review_item">
          <div class="review_item_details">
            <span class="item__title">
              {{ item.quantity | formatMoney(0) }}
              {{ item.quantity > 1 ? "Créditos" : "Crédito" }}
            </span>
            <small><a @click="step--">[ editar ]</a></small>
          </div>
          <div class="value_quantity">
            <div class="value">$ {{ item.subtotal | formatMoney }}</div>
          </div>
        </div>
        <div class="review_item" v-if="item.discount_amount > 0">
          <div class="review_item_details">
            Descuento
          </div>
          <div class="value_quantity">
            <div class="value">
              - $ {{ item.discount_amount | formatMoney }}
            </div>
          </div>
        </div>
        <hr />
        <div class="review_total">
          <span>TOTAL:</span>
          <span class="value">USD ${{ item.total | formatMoney }}</span>
        </div>
      </div>
      <hr />
      <div id="paypal-button-container"></div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
#checkout {
  section:not(:last-child) {
    margin-bottom: 2rem;
  }
  .item__title {
    font-weight: bold;
    color: $sitio3;
  }
  .review_item,
  .review_subtotal,
  .review_discount,
  .review_total {
    display: flex;
    justify-content: space-between;
  }
  .review_item_details {
    display: flex;
    > :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
  .review_total {
    font-size: 1.125em;
    font-weight: 900;
  }
  .value_quantity,
  .value {
    white-space: nowrap;
    text-align: right;
  }
  .config .options {
    display: table;
    width: 100%;
    font-weight: 500;
    > * {
      display: table-row;
      > * {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .quantity {
      color: $sitio2;
      // font-weight: bold;
    }
    .value {
      color: #000;
      // font-weight: 500;
    }
    .choose {
      text-align: right;
      padding: 0.375rem 0;
    }
  }
}
</style>
